import { message, Modal } from 'antd';
import { renderModal } from 'egenie-common';
import type { BatchReportData, PaginationData } from 'egenie-utils';
import { MainSubStructureModel, BatchReport, request, TimeStampFormatter } from 'egenie-utils';
import { values } from 'mobx';
import React from 'react';
import { logSubGrid } from '../../utils';
import { detailGrid } from './detailGrid';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store) {
  return new MainSubStructureModel({
    buttons: [
      {
        permissionId: '21',
        text: '审核',
        icon: 'icon-btn_xj',
        handleClick: () => {
          const ids = context.mainSubStructureModel.gridModel.selectRows.map((item) => item.wmsStOrderId)
            .join(',');
          if (!ids) {
            message.warning('请选择');
            return;
          }

          Modal.confirm({
            content: '确认审核?',
            onOk: () => request<BatchReportData>({
              url: '/api/cloud/wms/rest/bill/st/approve',
              method: 'post',
              data: { ids },
            })
              .then((info) => {
                renderModal(
                  <BatchReport
                    {...info.data}
                    columns={[
                      {
                        title: '单据编号',
                        dataIndex: 'orderNo',
                      },
                      {
                        title: '失败原因',
                        dataIndex: 'operationResult',
                      },
                    ]}
                  />
                );
                context.mainSubStructureModel.onQuery();
              }),
          });
        },
      },
      {
        permissionId: '3020',
        text: '推送至货主',
        icon: 'icon-btn_xj',
        handleClick: () => {
          const ids = context.mainSubStructureModel.gridModel.selectRows.map((item) => item.wmsStOrderId)
            .join(',');
          if (!ids) {
            message.warning('请选择');
            return;
          }

          Modal.confirm({
            content: '确认推送?',
            onOk: () => request<BatchReportData>({
              url: '/api/cloud/wms/rest/bill/st/inform',
              method: 'post',
              data: { ids },
            })
              .then((info) => {
                renderModal(
                  <BatchReport
                    {...info.data}
                    columns={[
                      {
                        title: '单据编号',
                        dataIndex: 'orderNo',
                      },
                      {
                        title: '失败原因',
                        dataIndex: 'operationResult',
                      },
                    ]}
                  />
                );
                context.mainSubStructureModel.onQuery();
              }),
          });
        },
      },
      {
        text: '导出',
        icon: 'icon-export',
        handleClick: () => {
          const ids = values(context.programme.gridModel.gridModel.selectedIds).join(',');
          const fileName = '盘点单';
          const exportType = 'cloud_wms_stock_take';
          const queryParam = context.programme.filterItems.params;
          const queryParamShow = context.programme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
    ],
    pageId: '1635',
    grid: {
      columns: [
        {
          key: 'authState',
          name: '审核状态',
          width: 80,
        },
        {
          key: 'wmsStOrderNo',
          name: '单据编号',
          width: 200,
        },
        {
          key: 'checkerName',
          name: '审核人',
          width: 150,
        },
        {
          key: 'warehouseName',
          name: '仓库',
          width: 150,
        },
        {
          key: 'createTime',
          name: '创建时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.createTime}/>,
        },
        {
          key: 'approvedTime',
          name: '审核时间',
          width: 150,
          formatter: ({ row }) => <TimeStampFormatter value={row.approvedTime}/>,
        },
        {
          key: 'creatorName',
          name: '创建人',
          width: 150,
        },
        {
          key: 'note',
          name: '备注',
          width: 150,
        },
        {
          key: 'stOrderType',
          name: '单据类型',
          width: 100,
        },
        {
          key: 'stockingTakingOrderState',
          name: '任务状态',
          width: 80,
        },
        {
          key: 'taskTakerName',
          name: '任务人',
          width: 150,
        },
        {
          key: 'informStatusStr',
          name: '通知状态',
          width: 90,
        },
        {
          key: 'difference',
          name: '盘点差异',
          width: 80,
          formatter: ({ row }) => (
            <span>
              {row.difference ? '有差异' : '无差异'}
            </span>
          ),
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsStOrderId',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 200,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/inventoryChecking/index/main',
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;
        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/bill/st/queryByPage',
          method: 'POST',
          data: {
            ...filterParams,
            ...rest,
          },
          headers: { warehouseId: filterParams?.warehouseId || '' },
        });
      },
    },
    subTables: {
      activeTab: 'detail',
      tabsFlag: {
        inited: {
          detail: true,
          log: false,
        },
        searched: {},
      },
      list: [
        detailGrid(),
        logSubGrid(60000, {}, 'warehouseId'),
      ],
    },
  });
}
