import type { FilterItemOptions, FilterSelect } from 'egenie-utils';
import { getWarehouseArea, getWarehouseBin } from '../../utils';
import type { Store } from './index';

export function filterItems(context: Store): FilterItemOptions[] {
  return [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: '0',
      data: [
        {
          value: '0',
          label: '创建时间',
        },
        {
          value: '1',
          label: '审核日期',
        },
      ],
    },
    {
      type: 'select',
      field: 'warehouseId',
      label: '仓库',
      onChangeCallback(warehouseId: string) {
        const warehouseAreaIdItem = context.programme.filterItems.getFilterItem('warehouseAreaId');
        warehouseAreaIdItem.reset();
        warehouseAreaIdItem.handleData([]);

        const warehouseBinIdItem = context.programme.filterItems.getFilterItem('warehouseBinId');
        warehouseBinIdItem.reset();
        warehouseBinIdItem.handleData([]);

        if (warehouseId) {
          getWarehouseArea({ warehouseId })
            .then((data) => context.programme.filterItems.addDict({ warehouseAreaId: data }));
        }
      },
    },
    {
      type: 'select',
      field: 'warehouseAreaId',
      mode: 'multiple',
      label: '库区',
      onChangeCallback(warehouseAreaId: string[]) {
        const warehouseBinIdItem = context.programme.filterItems.getFilterItem('warehouseBinId');
        warehouseBinIdItem.reset();
        warehouseBinIdItem.handleData([]);

        const warehouseId = (context.programme.filterItems.getFilterItem('warehouseId') as FilterSelect).value as string;

        if (warehouseAreaId) {
          getWarehouseBin({
            warehouseAreaId: warehouseAreaId ? warehouseAreaId.join(',') : undefined,
            warehouseId: warehouseId ? warehouseId : undefined,
          })
            .then((data) => context.programme.filterItems.addDict({ warehouseBinId: data }));
        }
      },

    },
    {
      type: 'select',
      mode: 'multiple',
      field: 'warehouseBinId',
      label: '库位',
    },
    {
      type: 'input',
      field: 'stockTakeOrderNo',
      label: '单据编号',
    },
    {
      type: 'input',
      field: 'skuNo',
      label: 'sku编码',
    },
    {
      type: 'input',
      field: 'uniqueCode',
      label: '唯一码',
    },
    {
      type: 'select',
      field: 'authState',
      label: '审核状态',
      data: [
        {
          value: '0',
          label: '未审核',
        },
        {
          value: '1',
          label: '已审核',
        },
      ],
    },
    {
      type: 'select',
      field: 'informStatus',
      label: '通知状态',
      data: [
        {
          value: '0',
          label: '未同步',
        },
        {
          value: '1',
          label: '已同步',
        },
      ],
    },
    {
      type: 'select',
      field: 'difference',
      label: '盘点差异',
      data: [
        {
          value: '0',
          label: '无差异',
        },
        {
          value: '1',
          label: '有差异',
        },
      ],
    },
  ];
}
