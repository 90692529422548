import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import React from 'react';
import type { SubItem } from './types';

export function detailGrid() {
  return {
    tab: {
      name: '盘点单明细',
      value: 'detail',
    },
    grid: {
      columns: [
        {
          key: 'productName',
          name: '商品名称',
          width: 150,
        },
        {
          key: 'productNo',
          name: '商品编码',
          width: 200,
          sortable: true,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
          width: 200,
          sortable: true,
        },
        {
          key: 'barCode',
          name: '条形码',
          width: 150,
        },
        {
          key: 'productPic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => {
            return <ImgFormatter value={row.productPic}/>;
          },
        },
        {
          name: '供应商',
          key: 'vendorNo',
          width: 150,
        },
        {
          key: 'warehouseAreaName',
          name: '库区',
          width: 150,
        },
        {
          key: 'warehouseBinName',
          name: '库位',
          width: 150,
        },
        {
          key: 'color',
          name: '颜色',
          width: 100,
        },
        {
          key: 'size',
          name: '尺码',
          width: 100,
        },
        {
          key: 'stockNum',
          name: '盘点前数量',
          width: 150,
        },
        {
          key: 'realNum',
          name: '实盘数量',
          width: 150,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'wmsStOrderDetailId',
      sortByLocal: false,
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/inventoryChecking/index/sub',
    },
    api: {
      onQuery: ({
        data,
        pid,
        cursorRow,
      }) => {
        const {
          sidx,
          sord,
          page,
          pageSize,
        } = data;
        return request<PaginationData<SubItem[]>>({
          url: '/api/cloud/wms/rest/bill/st/queryDetailsByPage',
          method: 'POST',
          data: {
            wmsStOrderId: pid,
            sidx,
            sord,
            page,
            pageSize,
          },
          headers: { warehouseId: cursorRow?.warehouseId || '' },
        });
      },
    },
  };
}
